import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ fileUrl, alignment, logoLinkTo }) => {
  return (
    
    <div className="logo_image"
      style={{
        textAlign: alignment || "center",
        padding: "1% 2% 1% 2%",
      }}
    >
      <Link to={logoLinkTo === null ? "/" : logoLinkTo}>
        {fileUrl && <img src={fileUrl} alt='Logo'/> }
      </Link>
    </div>
  );
};

export default Logo;
