import React, { useEffect } from 'react';
import { Drawer, Flex, Typography, Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import QuoteBreakdown from './QuoteBreakdown';
import * as Constant from '../Constants';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

const { Title, Text } = Typography;

const CartDrawer = ({open, setOpen}) => {
  const content = useSelector((state) => state.content?.contentData);
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const location = useLocation();

  const totals = useSelector(
    (store) => store?.quote?.info?.quote?.totals
  );
  const totalPrice = totals?.total / 100;
  const priceIncludedTax = quote?.taxes.every((tax) => tax.included_in_price);

  useEffect(() => {setOpen(false);}, [location]);

  return (
    <Drawer
      title={<Title level={4}
      style={{marginBottom:0}}
    >
      <Space>
        Shopping Cart
        <Text type='secondary'>
          ({quote?.items?.length} items)
        </Text>
      </Space>
    </Title>}
    placement="right"
    // closable={true}
    onClose={() => setOpen(false)}
    open={open}
    zIndex={1400}
    width={isMobile ? '90%' : 500}
    footer={
      <>
      {
        (quote && quote?.items?.length > 0) &&
        <>
        {
          isMobile ?
          <>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Text level={5} type='secondary'>{Constant.SUBTOTAL}</Text>

              <Text level={5} type='secondary'>
                {Constant.DOLLAR}
                {(totals?.subtotal / 100).toFixed(2)}
              </Text>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px"}}
              direction="row"
            >
              <Text level={5}
                style={{ color: content?.menu?.secondary_color, }}
              >{Constant.DISCOUNT}</Text>

              <Text level={5}
                style={{ color: content?.menu?.secondary_color, }}
              >
                {Constant.MINUS_DOLLAR}
                {(totals?.discount / 100).toFixed(2)}
              </Text>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Text level={5} type='secondary'>{priceIncludedTax ? Constant.TAX : Constant.TAX_INCLUDED_IN_PRICE}</Text>
              <Text level={5} type='secondary'>
                {Constant.DOLLAR}
                {(totals?.tax / 100).toFixed(2)}
              </Text>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Text level={4} strong>{Constant.TOTAL}</Text>

              <Text level={4} strong>
                {Constant.DOLLAR}
                {(totalPrice).toFixed(2)}
              </Text>
            </Flex>
            <div className="cart_continue_btn">
              <Link
                to="./checkout"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Button
                  // onClick={navigateCheckOut}
                  style={{
                    color: 'white',
                    height: '50px',
                  }}
                  type="primary"
                  block
                  disabled={quote?.limits?.exceeded_limits?.length > 0}
                  // size="large"
                >
                  <Text strong style={{color:'white',
                    fontSize: '1.2rem',
                  }}
                  >
                    {Constant.CHECK_OUT}

                  </Text>
                </Button>
              </Link>
            </div>
          </>
          :

          <>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Title level={5}>{Constant.SUBTOTAL}</Title>

              <Title level={5}>
                {Constant.DOLLAR}
                {(totals?.subtotal / 100).toFixed(2)}
              </Title>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px"}}
              direction="row"
            >
              <Title level={5}
                style={{ color: content?.menu?.secondary_color, }}
              >{Constant.DISCOUNT}</Title>

              <Title level={5}
                style={{ color: content?.menu?.secondary_color, }}
              >
                {Constant.MINUS_DOLLAR}
                {(totals?.discount / 100).toFixed(2)}
              </Title>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Title level={5}>{priceIncludedTax ? Constant.TAX : Constant.TAX_INCLUDED_IN_PRICE}</Title>

              <Title level={5}>
                {Constant.DOLLAR}
                {(totals?.tax / 100).toFixed(2)}
              </Title>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{ padding: "0 10px" }}
              direction="row"
            >
              <Title level={4}>{Constant.TOTAL}</Title>

              <Title level={4}>
                {Constant.DOLLAR}
                {(totalPrice).toFixed(2)}
              </Title>
            </Flex>
            <div className="cart_continue_btn">
              <Link
                to="./checkout"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Button
                  // onClick={navigateCheckOut}
                  style={{
                    color: 'white',
                    height: '50px',
                  }}
                  type="primary"
                  block
                  disabled={quote?.limits?.exceeded_limits?.length > 0}
                  // size="large"
                >
                  <Text strong style={{color:'white',
                    fontSize: '1.2rem',
                  }}
                  >
                    {Constant.CHECK_OUT}

                  </Text>
                </Button>
              </Link>
            </div>
          </>
          }
        </>
      }
      </>
    }
    >
      {
        (quote && quote?.items?.length > 0) ?
        <QuoteBreakdown />
        :
        <div className="empty_cart text-center">
          <img src={window.location.origin + "/images/Group 778.png"} alt="Empty Cart" />
          <Link
            className="btn btn-info mt-4"
            to="/"
            onClick={() => {
              setOpen(false);
            }}
          >
            {Constant.CONTINUE_SHOPPING}
          </Link>
        </div>
      }
    </Drawer>
  );
}


export default CartDrawer;
