import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { isMedMenu } from "../utility/generalUtils";
import { getReferenceNo } from '../utility/orderUtils';
import { Button, Flex, Space, Input, Form, InputNumber } from 'antd'

const UserInformation = ({handleClick}) => {
  const [form] = Form.useForm();
  const formData = useSelector(
    (store) => store?.quote?.info?.quote?.customer
  );
  const dispatch = useDispatch();

  const [inputValues, setInputValue] = useState({
    first_name: formData?.first_name,
    last_name: formData?.last_name,
    email: formData?.email,
    phone: formData?.telephone,
  });

  useEffect(() => {
    sessionStorage.setItem("inputValues", JSON.stringify(inputValues));
  }, [inputValues]);


  //handle submit updates

  function handleChange(event) {
    let name
    let value
    if (event.target === undefined){
      name = 'phone'
      value = event
    }else{
      name = event.target.name
      value = event.target.value
    }
    let formattedValue = value;
    if (name === 'first_name') {
      formattedValue = value.replace(/[^a-zA-Z]/g, '');
    }
    if (name === 'last_name') {
      formattedValue = value.replace(/[^a-zA-Z]/g, '');
    }
    if (name === 'phone') {
      formattedValue = value.toString() || '';
    }

    setInputValue({ ...inputValues, [name]: formattedValue });
  }

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reference_no) {
      updateUserInfo();
      handleClick(2);
    }
  };

  const updateUserInfo = () => {
    const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          customer: {
            first_name: inputValues?.first_name,
            last_name: inputValues?.last_name,
            email: inputValues?.email,
            telephone: inputValues?.phone,
            customer_type: isMedMenu() ? "medical" : "recreational",
          },
        },
        // },
      })
    );
  }

  return (
    <div className="container">
      <Form
        form={form}
        name="userInformation"
        onFinish={handleSubmit}
        scrollToFirstError
        layout="inline"
      >
        <Form.Item
          name="firstName"
          rules={[
            {required: true, message: 'Please input your First Name!' },
          ]}
        >
          <Input
            size='large'
            name="first_name"
            placeholder="First Name"
            onChange={(e) => handleChange(e)}
            onBlur={updateUserInfo}
            value={inputValues.first_name}
            defaultValue={inputValues?.first_name}
            style={{width: 270}}
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[
            { required: true, message: 'Please input your Last Name!' },
          ]}
          >
            <Input
              size='large'
              name='last_name'
              onChange={(e) => handleChange(e)}
              onBlur={updateUserInfo}
              value={inputValues.last_name}
              defaultValue={inputValues.last_name}
              placeholder="Last Name"
              style={{width: 270}}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'The input is not valid E-mail!' },
              { required: true, message: 'Please input your E-mail!' },
            ]}
          >
            <Input
              size='large'
              name='email'
              onChange={(e) => handleChange(e)}
              onBlur={updateUserInfo}
              value={inputValues.email}
              defaultValue={inputValues.email}
              placeholder="Email" style={{width: 270}}/>
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <InputNumber
              size='large'
              placeholder="Phone Number"
              controls={false}
              name='phone'
              onChange={(e) => handleChange(e)}
              value={inputValues.phone}
              defaultValue={inputValues.phone}
              onBlur={updateUserInfo}
              style={{width: 270}}
              formatter={(value) => {
                if (!value) return '';
                const phoneNumber = value.toString().replace(/\D/g, '');
                const areaCode = phoneNumber.slice(0, 3);
                const firstPart = phoneNumber.slice(3, 6);
                const secondPart = phoneNumber.slice(6, 10);
                return `(${areaCode}) ${firstPart}-${secondPart}`;
              }}
              parser={(value) => value.replace(/\D/g, '')}
            />
          </Form.Item>
      </Form>
      <Flex justify="flex-end" style={{marginTop: 20}}>
        <Button
          type='primary'
          size='large'
          onClick={handleClick}
          >
          Continue
        </Button>
      </Flex>
    </div>
  );
};
export default UserInformation;
