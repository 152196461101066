import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSelector } from "react-redux";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';

import { Pagination, Autoplay } from 'swiper/modules';
import { isMobile } from "react-device-detect";

const CarouselBanner = ({component}) => {
  const [isLoading] = useState(false);
  const [error] = useState(null);
  const { auto_play, auto_play_interval, slides } = component || {};


  const contentData = useSelector((state) => state?.content?.contentData?.components.filter((c) => c.type === "carousel")[0]);

  if (isLoading) {
    return <img className="mx-auto d-block" src="./images/loading.gif" alt="Loading..." />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!contentData) {
    // Handle the case where no content data is available
    return <p>No data found</p>;
  }

  const navigateSlide = (slide) => {
    if (slide?.url) {
      window.location.href = slide.url;
    }
  }

  // {
  //   "name": "Happy Hour",
  //   "file": {
  //     "url": "https://pbit-qa.s3.amazonaws.com/menu/carousel_slide/9/Artboard_6.png"
  //   },
  //   "url": "",
  //   "sync_with_discount": false,
  //   "discount_id": null,
  //   "start_date": null,
  //   "end_date": null,
  //   "days": [
  //     ""
  //   ],
  //   "start_at": null,
  //   "end_at": null
  // }

  const filteredSlides = slides.filter((slide) => {
    const { start_date, end_date, days, start_at, end_at } = slide;
    let now = new Date()
    const starting = new Date(start_date)
    const ending = new Date(end_date)
    let in_date_range = true
    let in_day_range = true
    let in_time_range = true
    // Check if slide falls within the specified date range
    if (
      (start_date && end_date) &&
      (starting > now || ending < now)
    ) { in_date_range = false }
    // Check if slide falls on any of the specified days
    if (days.length > 1 && !days.includes(now.toLocaleDateString('en-US', { weekday: 'long' }))) { in_day_range = false }
    // Check if slide falls within the specified time range
    const currentTime = now.getHours() * 60 + now.getMinutes();

    if (start_at && end_at) {
      // Parse start_at and end_at into minutes since midnight
      const parseTime = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (modifier === 'PM' && hours !== '12') {
          hours = parseInt(hours, 10) + 12;
        }
        if (modifier === 'AM' && hours === '12') {
          hours = 0; // Midnight edge case
        }
        return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      };

      const startTime = parseTime(start_at);
      const endTime = parseTime(end_at);

      if (startTime > currentTime || endTime < currentTime) {
        in_time_range = false;
      }
    }
    return in_date_range && in_day_range && in_time_range;
  });


  return (
    <section>
      <Swiper
        style={{maxWidth: 1600, margin: isMobile ? 8 : 'auto', borderRadius: isMobile ? 10 : '25px'}}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{ delay: auto_play_interval * 1000 }}
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true
        }}
      >
        {
          filteredSlides?.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={slide?.file?.url}
                  className="d-block w-100"
                  alt="..."
                  onClick={() => navigateSlide(slide)}
                />
              </SwiperSlide>
            );

          })
        }
      </Swiper>
    </section>
  );


}

export default CarouselBanner;
