import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";
import * as Constant from "../Constants";
import QuoteBreakdown from "./QuoteBreakdown";
import { Button, Flex } from 'antd'

const Review = ({handleClick}) => {
  const navigate = useNavigate();
  // const { product_types, strains, menu_items } = product_data;
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };

  const items = useSelector(
    (store) => store?.quote?.info?.quote?.items
  );




  return (
    <>
      {items?.length === 0 ? (
        <div className="empty_cart text-center container">
          <img src="./images/Group 778.png" alt='empty cart'/>
          <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
            {Constant.RETURN_TO_SHOPPING}
          </button>
        </div>
      ) : (
        <div className="">
          <div className="" style={{padding:15}}>
            <QuoteBreakdown />
            <Flex justify="flex-end">
              <Button
                type='primary'
                size='large'
                onClick={handleClick}
                >
                {Constant.CONTINUE}
              </Button>
            </Flex>
          </div>
        </div>
      )}
    </>
  );
};

export default Review;
