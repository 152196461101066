import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import * as Constant from "../Constants";
import { InputNumber, Radio, Typography, Spin, message, Space, Flex, Button, Divider } from "antd";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { notEmpty, notNull } from "../sharedFunctions.js";
import { getReferenceNo } from '../utility/orderUtils';
import { isMobile } from 'react-device-detect';

const { Text, Title, Paragraph } = Typography;

const Payment = ({handleClick, setFormStep}) => {
  const [redirecting, setRedirecting] = useState(false)
  const quote = useSelector((store) => store?.quote?.info?.quote);
  const settings = useSelector((store) => store?.content?.contentData?.menu);
  const [feeAmount, setFeeAmount] = useState(0)
  const [tipSettings, setTipSettings] = useState()
  const [tipAmount, setTipAmount] = useState(quote?.fulfillment?.tip_amount || 0)
  const [tipType, setTipType] = useState(quote?.fulfillment?.tip_type || 'percent')
  const [code, setCode] = useState(null);
  const location = useLocation();
  const [paymentMethod, setPaymentMethod] = useState(quote?.payment?.requested_payment_method || null);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if(tipSettings && tipType === 'amount' && !Object.values(tipSettings).includes(tipAmount)){
      setShowCustomTip(true)
    }
  },[tipSettings, tipAmount, tipType])

  const venue_id = useSelector((store) => store?.venue?.info?.venue?.venue_id);
  const reference_no = getReferenceNo(venue_id);

  const dispatch = useDispatch();
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );

  useEffect(() => {
    if(notEmpty(paymentMethod) && notNull(quote) && quote?.payment?.requested_payment_method !== paymentMethod){
      setReady(true)
      updateQuote()
    }
  }, [paymentMethod])

  const updateQuote = () => {
    if (notNull(reference_no)) {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
            quote: {
              payment: {
                requested_payment_method: paymentMethod,
              },
            },
        })
      );
    }
  }
  const defaultSlug = slugId;
  const continueButton = (e) => {
    e.preventDefault();
    //Update the form step to indicate completion of step 1
    setFormStep((prevState) => ({
      ...prevState,
      step3: true,
    }));
    handleClick(3);
  };

  const changePaymentMethod = (e) => {
    if(e.target.value !== 'posabit_pay' && tipAmount > 0){
      setTipAmount(0)
      setTipType('percent')
      // updateTip()
    }
    setPaymentMethod(e.target.value)
  };

  useEffect(() => {
    axios.get(`${sessionStorage.getItem("apiHost")}/mcx/${sessionStorage.getItem("merchantSlug")}/venue/${sessionStorage.getItem('selectedVenue')}/v1/info/posabit_pay`, {
      headers: {
          merchantToken: sessionStorage.getItem("merchantToken"),
        },
    })
    .then(res => {
      const feeSettings = res.data?.posabit_pay?.feeSettings
      setFeeAmount(feeSettings?.feePaidByConsumer?.value)
      setTipSettings(feeSettings?.tipOptions)
    })
    .catch(err => {
      console.log({err})
    })
  }, [])

  const handleLinkAccount = (e) => {
    e.preventDefault();
    updateQuote()
    const makeAuthorizationCall = async () => {
      try {
        setRedirecting(true)
        let authorizationEndpoint = ''
        let client_id = ''

        if (sessionStorage.getItem("apiHost") === 'https://app.posabit.com') {
          authorizationEndpoint = 'https://api.hypur.com/OAuth/authorize' 
          client_id = '4D74182FF49AEF82C05624C2C2B84B23'
        } else {
          authorizationEndpoint = 'https://tst-api.hypur.com/OAuth/Authorize'
          client_id =  'posabit'
        }

        const redirectUri = window.posabitmenuwidget.config.ecomm_url + location.pathname;

        const params = `?client_id=${client_id}&scope=payment:process&redirect_uri=${redirectUri}&response_type=code&state=1234`
        const oauth_url = authorizationEndpoint + params;
        window.location = oauth_url;

      } catch (error) {
        // Handle errors
        setRedirecting(false)
        message.warning({
          content: "Unable to Reach Posabit pay",
          icon: <i className="fa fa-info-circle" style={{color: 'blue'}}/>
        });
        console.error('Authorization error:', error);
      }
    };

    // Call the function to initiate the authorization process
    makeAuthorizationCall();
  }

  useEffect(() => {
    const getCodeFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const codeFromUrl = searchParams.get('code');

      if (codeFromUrl) {
        setCode(codeFromUrl);
      }
    };
    getCodeFromUrl();
  }, [location.search]);

  useEffect(() => {updateTip()}, [tipAmount, tipType, feeAmount])

  const updateTip = () => {
    if (reference_no) {
      const slugId = sessionStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
            quote: {
              fulfillment: {
                tip_amount: tipAmount,
                tip_type: tipType,
                processing_fee: feeAmount * 100
              },
            },
        })
      );
    }
  }

  const handleTipChange = (e) => {
    const val = notNull(e?.target?.value) ? e.target.value : e;
    if(val === 'other') {
      setShowCustomTip(true);
      setTipAmount(5);
      setTipType('amount');
    }else if(val === 'none') {
      setShowCustomTip(false);
      setTipAmount(0);
      setTipType('percent');
    }else {
      setShowCustomTip(false);
      setTipAmount(parseInt(val))
      setTipType(tipSettings.mode === 1 ? 'amount' : 'percent')
    }
  }

  return (
    <div className="container">
      <Spin
        fullscreen
        style={{zIndex: 1400}}
        spinning={redirecting}
      />
      <form onSubmit={continueButton}>
        <Title level={4}>Select Payment Method:</Title>
        <Radio.Group
          value={paymentMethod}
          onChange={changePaymentMethod}
          size='large'
        >
          <Space direction="vertical" size='large'>
            {
              settings?.ecommerce_accepts_cash &&
              <Radio value={'cash'}>Cash</Radio>
            }
            {
              settings?.ecommerce_accepts_debit &&
              <Radio value={'debit'}>Debit</Radio>
            }
            {
              settings?.ecommerce_accepts_posabit_pay &&
              <Radio value={'posabit_pay'}>
                <Space wrap>
                  {
                    notNull(settings?.ecommerce_posabit_pay_friendly_name) && settings?.ecommerce_posabit_pay_friendly_name !== 'Posabit Pay' ?
                      <Text strong>{settings?.ecommerce_posabit_pay_friendly_name}</Text>
                    :
                      <img src="/images/POSaBIT Pay Logo Final - Horizontal-3.jpg" alt="pay" style={{width: 100}}/>
                  }
                  {
                    feeAmount > 0 &&
                    <Text>(${feeAmount.toFixed(2)} fee)</Text>
                  }
                  {
                    code ?
                    <>
                      <Text style={{color: '#89c03b'}}>Account Linked</Text>
                      <img style={{width: 30}} src="/images/Artboard 30_1.png" alt="checkmark" />
                    </>
                    :
                    <>
                      <Text style={{color: '#e74d4b'}}>Account Unlinked</Text>
                      <img style={{width: 30}} src="/images/Artboard 30.png" alt="xmark" />
                    </>
                  }
                </Space>
                <Paragraph style={{marginBottom: 0}}>
                  Secure electronic payment directly from checking account
                </Paragraph>
              </Radio>
            }
          </Space>
        </Radio.Group>
        {paymentMethod === 'posabit_pay' && <div>
              <Title level={4}>Tip:</Title>
              <Radio.Group
                size={isMobile ? 'small' : 'large'}
                onChange={handleTipChange}
                // defaultValue={tipSettings && Object.values(tipSettings).includes(tipAmount) ? tipAmount : 'other'}
                defaultValue={tipAmount === 0 ? 'none' : tipAmount}
              >
                {
                  (notNull(tipSettings) && tipSettings.mode > 0) && Object.keys(tipSettings)?.map(tip => {
                    if(tip !== 'mode' && tipSettings[tip] > 0){
                      if(tipSettings.mode === 1){
                        return <Radio.Button key={tip} value={tipSettings[tip]}>{`$${tipSettings[tip].toFixed(2)}`}</Radio.Button>
                      }else{
                        return <Radio.Button key={tip} value={tipSettings[tip]}>{tipSettings[tip]}%</Radio.Button>
                      }
                    }else{ return null}
                  })
                }
                <Radio.Button value={'other'}>Other</Radio.Button>
                <Radio.Button value={'none'}>None</Radio.Button>
              </Radio.Group>

              {showCustomTip && (
                <div>
                <InputNumber
                  size='large'
                  min={0}
                  style={{
                    width: isMobile ? '100%' : '333px',
                    marginTop: 10
                  }}
                  placeholder="5.00"
                  precision={2}
                  prefix={'$'}
                  value={tipAmount}
                  onChange={(e) => {
                    setTipAmount(e)
                    setTipType('amount')
                    // updateTip()
                  }}
                />
                </div>
              )}
            </div>}
          <Flex justify="flex-end" style={{marginTop: 20}}>
            <Space>
              {
                paymentMethod === 'posabit_pay' && code === null?
                <Button
                  type='primary'
                  size='large'
                  disabled={!ready}
                  onClick={handleLinkAccount}
                >
                  {Constant.LINK_ACCOUNT}
                </Button>
                :
                <Button
                  type='primary'
                  size='large'
                  disabled={!ready}
                  onClick={continueButton}
                >
                  {Constant.CONTINUE}
                </Button>

              }


            </Space>
          </Flex>

      </form>
      {/* </div> */}
    </div>
  );
};
export default Payment;

