import React from "react";
import ProductSlider from "./ProductSlider";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import * as Constant from "../Constants";
import { Divider, Typography } from "antd";
import { isMobile } from "react-device-detect";

const ProductCategory = ({component}) => {
  const { id, name, safe_name } = component;
  const featured_products = component?.locals?.featured_products
  const { info, error, status } = useSelector((state) => state.menuFeed || {});
  const isMedicalOnly = sessionStorage.getItem("menuType") === "Recreational" ? false : true


  if (error) {
    return <div>{Constant.ERROR} {error}</div>;
  }

  const filteredProducts = info?.product_data?.menu_items?.filter((item) => {
    if (isMedicalOnly) {
      // Apply medical filter
      return item?.variants?.some(v => v?.med_price_cents) && item?.category_ids.includes(id);
    }
    // No filter applied
    else {
      return item?.category_ids.includes(id);
    }
  });

  // const products = (isMedicalOnly ? info?.product_data?.menu_items?.filter(prod => prod?.variants?.some(v => v?.med_price_cents)) : info?.product_data?.menu_items) || [];
  const discounts = info?.product_data?.discounts

  if (status === 'succeeded' && filteredProducts?.length === 0) { return null}

  return (
    <section id="product_section" style={{maxWidth: 1600, margin: 'auto'}}>
      {
        <div className="container-fluid ">
          {
            (isMobile && name?.length > 30) ?
            <Typography.Title level={3}
              ellipsis={{rows: 5}}
              >{name}</Typography.Title>
              :
            <Divider orientation="left" orientationMargin={0}>
              <Typography.Title level={3}
              ellipsis={{rows: 5}}
              >{name}</Typography.Title>
            </Divider>
          }
          <div className="text-end">
            <Link to={`/products?category=${safe_name}`} state={{ filterTitle: `Category: ${name}` }}>
              {Constant.VIEW_ALL}
            </Link>
          </div>
          <React.Fragment>
            <ProductSlider products={filteredProducts} ids={id} discount={discounts?.id} featured_products={featured_products}/>
          </React.Fragment>
        </div>
      }
    </section>
  );
};

export default ProductCategory;
