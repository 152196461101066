import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { capCase, arraySubtractAll, notNull } from '../sharedFunctions';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Flex,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Space,
  Tag,
  Typography ,
  Cascader
} from 'antd';
import { useNavigate } from 'react-router';
import { isMobile } from 'react-device-detect';
import { parseFiltersfromURL } from '../utility/productUtils';
import { isEmpty } from '../utility/generalUtils';
import { isMedMenu } from '../utility/generalUtils';
import { applyFilters, getWeightTypeIds } from '../utility/filterUtils';
import Subcategory from './Subcategory';

const { Text, Paragraph } = Typography

const FilterModal = ({showModal, setShowModal}) => {
  const content = useSelector((state) => state.content?.contentData);
  const menu = content?.menu || {};
  const filters = content?.filters || [];
  const [parentFilter, setParentFilter] = useState();
  const [activeFilters, setActiveFilters] = useState(parseFiltersfromURL(filters.map(f => f.filter_type)));
  const [filterWord, setFilterWord] = useState('');
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);
  const [filterCounts, setFilterCounts] = useState({});

  const sdpCategories = useSelector((state) => state.sdpData?.info?.categories || []);
  const menuOptions = useSelector((state) => state.menuFeed.info?.product_data || {});
  const menufeedItems = useSelector((state) => state.menuFeed.info?.product_data.menu_items);
  // const { weights, brands, product_types, categories, strains, terpenes, flavors, effects } = menuOptions;
  const weights = menuOptions?.display_weights || []
  const brands1 = menuOptions?.brands || []
  const deals = menuOptions?.discounts || []
  const sortedBrandNames = brands1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const types = menuOptions?.product_types || []
  const sortedTypesNames = types.slice().sort((a, b) => a.name.localeCompare(b.name));
  const categories1 = menuOptions?.categories || []
  const sortedCategoriesNames = categories1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const subcategories1 = menuOptions?.categories
    ?.filter(cat => cat.subcategories.length > 0)
    ?.map(cat => cat.subcategories).flat() || []
  const sortedSubCategoriesNames = subcategories1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const strains1 = menuOptions?.strains || []
  const sortedStrainsNames = strains1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const terpenes1 = menuOptions?.terpenes || []
  const sortedTerpenesNames = terpenes1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const effects = menuOptions?.effects || []
  const sortedEffectsNames = effects.slice().sort((a, b) => a.name.localeCompare(b.name));
  const flavors1 = menuOptions?.flavors || []
  const sortedFlavorsNames = flavors1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const medMenu = isMedMenu();

  const filtersMap = {
    category: sortedCategoriesNames,
    subcategory: sortedSubCategoriesNames,
    brand: sortedBrandNames,
    product_type: sortedTypesNames,
    doh_compliant: [{id: 'doh_compliant', name: 'View All Products'}],
    strains: sortedStrainsNames,
    terpenes: sortedTerpenesNames,
    flavors: sortedFlavorsNames,
    effects: sortedEffectsNames,
    weights: weights.map((weight) => { return {id: weight, name: weight} }),
    deals: deals,
    lineage: [
      {id: 'sativa', name: 'Sativa'},
      {id: 'indica', name: 'Indica'},
      {id: 'hybrid', name: 'Hybrid'},
      {id: 'sativa hybrid', name: 'Sativa Hybrid'},
      {id: 'indica hybrid', name: 'Indica Hybrid'},
      {id: 'cbd', name: 'CBD'},
    ],
    price: [
      {id: 'Under $20', name: 'Under $20'},
      {id: '$20-$40', name: '$20-$40'},
      {id: '$40-$60', name: '$40-$60'},
      {id: '$60-$80', name: '$60-$80'},
      {id: '$80 & Above', name: '$80 & Above'},
    ],
    thc_potency: [
      {id: 'Low (1-10%)', name: 'Low (1-10%)'},
      {id: 'Medium (10%-20%)', name: 'Medium (10%-20%)'},
      {id: 'High (>20%)', name: 'High (>20%)'},
    ],
    sdp_category: sdpCategories.map((category) => { return {id: category.sdp_category, name: category.name} }),
  }

  useEffect(() => {
    setActiveFilters(parseFiltersfromURL(filters.map(f => f.filter_type)));
  }, [showModal]);

  // Figure out the counts for each filter option
  useEffect(() => {
    const newFilterCounts = {
      category: {},
      subcategory: {},
      brand: {},
      strains: {},
      product_type: {},
      doh_compliant: {},
      terpenes: {},
      flavors: {},
      effects: {},
      weights: {},
      deals: {},
      lineage: {},
      price: {},
      thc_potency: {},
      sdp_category: {},
    };

    // Set the filters for the counts.  We should remoce the current filter
    // from the list of filters to count so the user can see the effect of adding addional filters.
    const filtersForCount = {...activeFilters};
    delete filtersForCount[parentFilter];
    if( parentFilter === 'category' ){
      delete filtersForCount['subcategory'];
    }
    const filteredItems = applyFilters(menuOptions, filtersForCount);
    if (!filteredItems) {
      return;
    }

    const weightTypeIds = getWeightTypeIds(types);

    deals.forEach((deal) => {
      newFilterCounts.deals[deal.id] = 0;
    });
    filteredItems?.forEach((item) => {
      if (item?.category_ids) {
        item.category_ids?.map(cat_id => sortedCategoriesNames?.find(cat => cat.id === cat_id)?.safe_name).forEach((categoryName) => {
          newFilterCounts.category[categoryName] = newFilterCounts.category[categoryName] ? newFilterCounts.category[categoryName] + 1 : 1;
        });
      }
      if (item?.subcategory_ids) {
        // if(item.subcategory_ids.length > 0){debugger}
        item.subcategory_ids?.map(cat_id => sortedSubCategoriesNames?.find(cat => cat.id === cat_id)?.safe_name).forEach((subcategoryName) => {
          newFilterCounts.subcategory[subcategoryName] = newFilterCounts.subcategory[subcategoryName] ? newFilterCounts.subcategory[subcategoryName] + 1 : 1;
        });
      }
      if (item?.brand_id) {
        newFilterCounts.brand[item.brand_id] = newFilterCounts.brand[item.brand_id] ? newFilterCounts.brand[item.brand_id] + 1 : 1;
      }
      if (item?.strain_id) {
        newFilterCounts.strains[item.strain_id] = newFilterCounts.strains[item.strain_id] ? newFilterCounts.strains[item.strain_id] + 1 : 1;
      }
      if (item?.product_type_id) {
        newFilterCounts.product_type[item.product_type_id] = newFilterCounts.product_type[item.product_type_id] ? newFilterCounts.product_type[item.product_type_id] + 1 : 1;
      }
      if (item?.terpene_ids) {
        item.terpene_ids.forEach((terpeneId) => {
          newFilterCounts.terpenes[terpeneId] = newFilterCounts.terpenes[terpeneId] ? newFilterCounts.terpenes[terpeneId] + 1 : 1;
        });
      }
      if (item?.flavor_ids) {
        item.flavor_ids.forEach((flavorId) => {
          newFilterCounts.flavors[flavorId] = newFilterCounts.flavors[flavorId] ? newFilterCounts.flavors[flavorId] + 1 : 1;
        });
      }
      if (item?.effect_ids) {
        item.effect_ids.forEach((effectId) => {
          newFilterCounts.effects[effectId] = newFilterCounts.effects[effectId] ? newFilterCounts.effects[effectId] + 1 : 1;
        });
      }
      if (item?.flower_type) {
        const lineage = item.flower_type.toLowerCase().replace(/_/g, ' ');
        newFilterCounts.lineage[lineage] = newFilterCounts.lineage[lineage] ? newFilterCounts.lineage[lineage] + 1 : 1;
      }
      if (item?.sdp_category) {
        newFilterCounts.sdp_category[item.sdp_category] = newFilterCounts.sdp_category[item.sdp_category] ? newFilterCounts.sdp_category[item.sdp_category] + 1 : 1;
      }

      deals.forEach((deal) => {
        if ( item.variants.some(variant => variant.discount_ids.includes(deal.id)) ) {
          newFilterCounts.deals[deal.id] = newFilterCounts.deals[deal.id] + 1;
        }
      });

      if( item?.test_results?.thc?.unit_type === '%') {
        const thcPotencyCategory = (item?.test_results?.thc?.current < 10 && item?.test_results?.thc?.current >0) ? 'Low (1-10%)' :
          item?.test_results?.thc?.current < 20 ? 'Medium (10%-20%)' : 'High (>20%)';
        newFilterCounts.thc_potency[thcPotencyCategory] = newFilterCounts.thc_potency[thcPotencyCategory] ? newFilterCounts.thc_potency[thcPotencyCategory] + 1 : 1;
      }

      if (item.variants.some(variant => variant.doh_compliant)) {
        newFilterCounts.doh_compliant['doh_compliant'] = newFilterCounts.doh_compliant['doh_compliant'] ? newFilterCounts.doh_compliant['doh_compliant'] + 1 : 1;
      }

      item.variants.forEach((variant) => {
        if (weightTypeIds.includes(item.product_type_id)) {
          if (variant?.display_weight) {
            newFilterCounts.weights[variant.display_weight] = newFilterCounts.weights[variant.display_weight] ? newFilterCounts.weights[variant.display_weight] + 1 : 1;
          }
        }

        const priceCategory = computePriceCategory(variant);
        newFilterCounts.price[priceCategory] = newFilterCounts.price[priceCategory] ? newFilterCounts.price[priceCategory] + 1 : 1;
      });

    });

    function computePriceCategory(variant ) {
      let price = 0;
      if( medMenu ){
        price = variant.discounted_med_price_cents || variant.med_price_cents;
      }else{
        price = variant.discounted_price_cents || variant.price_cents;
      }
      if (price < 2000) {
        return 'Under $20';
      } else if (price < 4000) {
        return '$20-$40';
      } else if (price < 6000) {
        return '$40-$60';
      } else if (price < 8000) {
        return '$60-$80';
      } else {
        return '$80 & Above';
      }
    }
    setFilterCounts(newFilterCounts);

  }, [menufeedItems, medMenu, parentFilter, activeFilters]);


  const filterOptions = () => {
    const filtersArray =  filters?.filter(f => f.active)?.map((filter) => {
      return (
        {
          value: filter.filter_type,
          label: filter.display_name,
          children: filtersMap[filter.filter_type]?.map((option) => {
            if (filter.filter_type === 'category') {
              return {
                value: option.safe_name,
                label: option.name,
                children: option?.subcategories?.map((subcategory) => {
                  return {
                    value: subcategory.safe_name,
                    label: subcategory.name,
                  };
                }),
              };
            }else {
              return {
                value: option.id,
                label: option.name,
              };
            }
          })
        }
      )
    }).filter(f => f.children?.length > 0);
    return filtersArray;
  }

  const findFilterName = (type,value) => {
    switch(type){
      case 'lineage':
      case 'weights':
      case 'thc_potency':
      case 'price':
        return value
      case 'category':
      case 'subcategory':
        return filtersMap[type]?.find((option) => (option?.safe_name?.toString() === value?.toString()))?.name
      case 'doh_compliant':
        return 'All'
      default:
        return filtersMap[type]?.find((option) => (option?.id?.toString() === value?.toString()))?.name
    }
  }

  // Clear the filters.  Make sure to retain the search filter, if there is one.
  const clearFilters = () => {
    const searchFilter = activeFilters['search'];
    if (searchFilter) {
      setActiveFilters({search: searchFilter});
    } else {
      setActiveFilters({});
    }
  }

  const removeFilter = (filter, value) => {
    const newFilters = {...activeFilters};
    newFilters[filter] = newFilters[filter].filter((item) => item !== value);
    if (newFilters[filter].length === 0) {
      delete newFilters[filter];
    }
    setActiveFilters(newFilters);
  }

  const options = filterOptions();

  const updateFilters = (filter, values) => {
    const newFilters = {...activeFilters};
    // debugger
    if(['category', 'subcategory'].includes(filter)){
      // debugger
      const event = values // this is an event object when the filter is category or subcategory
      // if filter = category and is checked, add all subfilters from options to the newFilters
      if(filter === 'category'){
        const subcategories = options?.find(f => f.value === 'category')?.children?.find(f => f.value == event.target.value)?.children?.map(sub => sub.value)
        if(event.target.checked){
          // newFilters['subcategory'] = notNull(newFilters['subcategory']) ? [...newFilters['subcategory'], ...subcategories] : subcategories
          if(!('subcategory' in newFilters)){newFilters['subcategory'] = []}
          newFilters['subcategory'] = arraySubtractAll(newFilters['subcategory'], subcategories)
          newFilters['category'] = notNull(newFilters['category']) ? [...newFilters['category'], event.target.value] : [event.target.value]
        }else{
          // debugger
          newFilters['category'] = newFilters['category'].filter((item) => item !== event.target.value);
        }
      }else{
        const category = options?.find(f => f.value === 'category')?.children?.find(f => f.children?.find(sub => sub.value == event.target.value))?.value
        if(!('subcategory' in newFilters) || !newFilters['subcategory']?.includes(event.target.value)){
          newFilters['subcategory'] = notNull(newFilters['subcategory']) ? [...newFilters['subcategory'], event.target.value] : [event.target.value]
          // newFilters['category'] = notNull(newFilters['category']) ? [...newFilters['category'], category] : [category]
          if(newFilters['category']?.includes(category)){newFilters['category'] = newFilters['category'].filter(cat => cat !== category)}
        }else{
          newFilters['subcategory'] = newFilters['subcategory'].filter((item) => item !== event.target.value);
        }
      }
      if( newFilters['category']?.length === 0){
        delete newFilters['category']
      }
      if( newFilters['subcategory']?.length === 0){
        delete newFilters['subcategory']
      }
      // if filter = category and is unchecked, remove all subfilters from options from the newFilters
      // if filter is subcategory, add or remove the subcategory from the newFilters

      // if(filter === 'category' && notNull(newFilters[filter]) && newFilters[filter].includes(values)){
      //   newFilters[filter] = newFilters[filter].filter((item) => item !== values);
      //   // newFilters['subcategory'].filter(item => filter.includes);
      // }
      // if(notNull(newFilters[filter]) && newFilters[filter].includes(values)){
      //   newFilters[filter] = newFilters[filter].filter((item) => item !== values);
      // }else{
      //   newFilters[filter] = notNull(newFilters[filter]) ? [...newFilters[filter], values] : [values]
      // }
    }else{
      newFilters[filter] = values;
      if(newFilters[filter].length === 0){
        delete newFilters[filter];
      }
    }
    setActiveFilters(newFilters);
  }

  const isChecked = (child) => {
    if(('subcategory' in activeFilters)){return false}
    const checked = activeFilters['subcategory']?.includes(child.value.toString())
    return checked
  }

  const filterNavigate = () => {
    if (!isEmpty(activeFilters)) {
      setShowModal(false)
      const queryParams = new URLSearchParams(activeFilters).toString();
      navigate(`/products?${queryParams}`, {state: {filters: activeFilters}});
    }else{
      navigate('/')
    }
  }
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      title="Menu Filters"
      width={500}
      style={{ top: 20 }}
      zIndex={1400}
      okText="Apply Filters"
      cancelText="Reset"
      closeIcon={<i className="fa fa-times" />}
      maskClosable={true}
      footer={<Flex justify='space-between'>
        <Button onClick={() => clearFilters()}>Reset</Button>
        <Space>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button type='primary' onClick={filterNavigate}
          >
            Apply Filters
          </Button>
        </Space>
      </Flex>}
    >
      <Row>
        <Col span={isMobile ? 10 : 10}>
          <Menu theme='light' mode='inline'
            defaultSelectedKeys={['category']}
            inlineIndent={4}
            style={{width: '100%', height: '50vh', overflow: 'auto'}}
          >
            {filters?.filter(f => f?.active && filtersMap[f?.filter_type]?.length > 0)?.map((filter) => {
              return (
                <Menu.Item key={filter.position} onClick={() => {
                  isMobile && setShowDrawer(true)
                  setParentFilter(filter.filter_type)
                  }}
                >
                  <Badge count={activeFilters[filter.filter_type]?.length} offset={[10,0]}
                    style={{backgroundColor:  menu?.secondary_color}}
                    size='small'
                  >
                    <Text>{filter.display_name}</Text>
                  </Badge>
                </Menu.Item>
              );
            })}
          </Menu>
        </Col>
        {parentFilter &&
          <Col span={isMobile ? 14 : 14}>
          <Input.Search
            onChange={(e) => setFilterWord(e.target.value)}
            placeholder="Search Filters"
            allowClear={{clearIcon: <i className="fa fa-times" />}}
          />
          <Checkbox.Group style={{width: '100%'}}
            // value={activeFilters}
            defaultValue={activeFilters[parentFilter]}
            value={activeFilters[parentFilter]}
            key={parentFilter}
            onChange={(values) => {
              // debugger
              if(parentFilter !== 'category'){
                updateFilters(parentFilter, values);
              }
            }}
          >

            <List
              bordered
              style={{width: '100%', height: '47vh', overflow: 'auto'}}
            >
              { parentFilter && options?.find(f => f.value === parentFilter) !== undefined &&
                options?.find(f => f.value === parentFilter)?.children
                ?.map((filter) => {
                  const menuItems = [
                    {
                      label: <Space>
                          <Checkbox
                            onChange={event => updateFilters('category', event)}
                            value={filter.value.toString()}
                            name={filter.value}
                            key={filter.value}
                            indeterminate={filter?.children?.some(child => activeFilters['subcategory']?.includes(child.value.toString()))}
                            checked={activeFilters['category']?.includes(filter.value.toString())}
                          />
                          <Text>
                            {filter.label} {filterCounts[parentFilter]
                            ? '(' + (filterCounts[parentFilter][filter.value] || '0') + ')'
                            : '(0)'
                            }
                          </Text>
                        </Space> ,
                      children: filter?.children?.map((child) => {
                        return (
                          {
                            key: child.value,
                            label: (<Subcategory
                              updateFilters={updateFilters}
                              child={child}
                              filterCounts={filterCounts}
                              parentFilter={parentFilter}
                              activeFilters={activeFilters}
                            />)
                          }

                        )
                      }
                    )

                    }
                  ]
                  // if(filter?.children?.length > 0){debugger}
                  return (
                    <List.Item key={filter.value}
                    style={{
                      display: (filter.label?.toLowerCase().includes(filterWord?.toLowerCase()) 
                      && filterCounts[parentFilter] && filterCounts[parentFilter][filter.value] > 0)
                      ? 'block' : 'none',
                      padding: filter?.children?.length > 0 ? '0' : '10 0 10 20px'
                    }}
                    >
                      <List.Item.Meta
                        description={
                          <>
                            {
                              filter?.children?.length > 0 ?
                              <Menu
                                items={menuItems}
                                style={{width: '100%', padding: 0}}
                                mode='inline'
                                inlineIndent={20}
                                // variant='borderless'
                              />
                              :
                              <Checkbox
                                value={filter.value.toString()}
                                key={filter.value}
                                onChange={event => {
                                  console.log('filter', filter)
                                  if(parentFilter === 'category'){
                                    updateFilters('category', event)
                                  }
                                }}
                              >
                                {filter.label} {filterCounts[parentFilter]
                                ? '(' + (filterCounts[parentFilter][filter.value] || '0') + ')'
                                : '(0)'
                                }
                              </Checkbox>
                            }
                          </>
                        }
                      />
                    </List.Item>
                  )
                }
                )
              }
            </List>
          </Checkbox.Group>
        </Col>
        }
      </Row>
      <Row>
        <Divider>Active Filters</Divider>
        {
          Object.values(activeFilters).flat()?.length > 0 &&
          <Space wrap size={[0,4]}>
            {Object.keys(activeFilters)?.map((filter) => {
              if (filter === 'search') {
                return("");
              }
              return activeFilters[filter]?.map((value) => {
                return (
                  <Tag key={filter + value}>
                    {filters?.concat({
                      filter_type: 'subcategory',
                      display_name: 'Subcategory',
                    }).find((f) => f.filter_type === filter)?.display_name + ": " + findFilterName(filter,value)}
                    <i
                      className="fa fa-times"
                      onClick={() => removeFilter(filter, value )}
                      style={{marginLeft: '4px'}}
                    />
                  </Tag>
                )
              })
            })}
          </Space>
        }
      </Row>
    </Modal>
  )
}

export default FilterModal;
